import styled from 'styled-components';
import { H1 } from 'components/AppStyle/Text';
import { mediaQueries } from 'helper/mediaQueries';

const HeaderLogo = styled(H1)`
  font-family: ${props => props.theme.subFamily};
  font-size: 6rem;
  ${mediaQueries('md')('font-size: 4rem;')}
`;

const Header = () => {
  return (
    <div className="page-header">
      <HeaderLogo>
        VORTRUPP
      </HeaderLogo>
    </div>
  )
}

export default Header;
