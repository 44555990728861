import en from './en.json';
import zhtw from './zh-tw.json';

const locale = {
    en: {
        translation: en,
    },
    'zh-TW': {
        translation: zhtw,
    }
}

export default locale;
