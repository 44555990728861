import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
`;

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 500;
`;

const Text = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
`;

export { H1, H2, Text };
