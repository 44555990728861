import React from 'react';
import AppRoutes from './routes';
import Layout from 'components/Layout';
import Theme from 'components/Theme';

import 'styles/index.scss';

function App() {
  return (
    <Theme>
      <Layout>
        <AppRoutes />
      </Layout>
    </Theme>
  );
}

export default App;
