const breakpoints = {
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const mediaQueries = key => {
  return style => `@media (min-width: ${breakpoints[key]}px) { ${style} }`
}
