import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import About from '../pages/About';
// import Contact from '../pages/Contact';

const Home = React.lazy(() => import('../pages/Home'));

function useLanguage() {
  const { locale } = useParams();
  const { i18n } = useTranslation();
  React.useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);
}

const LanguageBaseRoute = () => {
  const { path } = useRouteMatch();
  useLanguage();
  return (
    <Switch>
      <Route exact path={path} component={Home} />
    </Switch>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:locale(en|zh-TW)">
            <LanguageBaseRoute />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default AppRoutes;
