import { ThemeProvider } from 'styled-components';

const themeConfig = {
  color: {
    primaryColor: '#293250',
    secondaryColor: '#FFD55A',
    tertiaryColor: '#6DD47E',
    borderColor: '#A8A8A8',
    backgroundWhite: '#f7f7f7',
  },
  subFamily: 'Carter One'
}

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={themeConfig}>
      {children}
    </ThemeProvider>
  );
};

export default Theme;
