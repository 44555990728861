import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Layout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="page-layout">
      <Helmet>
        <title>
          {t('og.title')}
        </title>
        <link rel="canonical" href="https://vortrupp.com" />
        <meta name="description" content={t('og.description')} />
      </Helmet>
      <Header />
      { children }
      <Footer />
    </div>
  )
}

export default Layout;
