import styled from 'styled-components';

const FooterStyle = styled.footer`
  font-size: 1.4rem;
  line-height: 1;
  padding: 1rem 0;
  font-weight: 600;
  text-align: center;
  background-color: ${props => props.theme.color.secondaryColor};
`;

const Footer = () => {
  return (
    <FooterStyle>
      Copyrights © 2020 All Rights Reserved by Vortrupp.
    </FooterStyle>
  )
}

export default Footer;
